import CustomStore from "devextreme/data/custom_store";
import hiddenMessageService from "../../services/hidden-message.service";

const HiddenMessageStore = {
    namespaced: true,

    /* State */
    state: {
        hiddenmessage: {},
        hiddenmessages: []
    },

    /* Mutations */
    mutations: {
        SET_DATA(state, payload) {
            state.hiddenmessage = payload;
        },
        SET_LIST(state, payload) {
            state.hiddenmessages = payload;
        },
        CLEAR_DATA(state) {
            state.hiddenmessage = {};
        }
    },

    /* Actions */
    actions: {
        // Bütün gizli mesajları gətirmək üçün
        getHiddenmessages({commit}, payload = null) {
            const data = new CustomStore({
                key: "id",
                load: function(loadOptions) {
                    return hiddenMessageService.get(null, {
                        datatable: true,
                        ...loadOptions,
                        ...payload
                    })
                        .then(response => {
                            if (response && response.data && response.data.response) {
                                return {
                                    data: response.data.response.data || [],
                                    totalCount: response.data.response.total || 0
                                };
                            }
                            return {
                                data: [],
                                totalCount: 0
                            };
                        })
                        .catch(error => {
                            console.error('Gizli mesajları gətirərkən xəta baş verdi:', error);
                            return {
                                data: [],
                                totalCount: 0
                            };
                        });
                }
            });
            commit('SET_LIST', data);
        },

        // Tək bir gizli mesajı ID-yə görə gətirmək üçün
        getHiddenmessage({commit}, id) {
            return hiddenMessageService.get(id)
                .then(response => {
                    if (response && response.data && response.data.response) {
                        commit('SET_DATA', response.data.response);
                        return response.data.response;
                    }
                    throw new Error('Məlumat tapılmadı');
                })
                .catch(error => {
                    console.error('Gizli mesajı gətirərkən xəta baş verdi:', error);
                    throw error;
                });
        },

        // Select üçün gizli mesajları gətirmək
        getSelectHiddenmessages({commit}, payload = {}) {
            return hiddenMessageService.get(null, payload)
                .then(response => {
                    if (response && response.data && response.data.response) {
                        commit('SET_LIST', response.data.response);
                        return response.data.response;
                    }
                    return [];
                })
                .catch(error => {
                    console.error('Select mesajları gətirərkən xəta baş verdi:', error);
                    return [];
                });
        },

        // Yeni gizli mesaj əlavə etmək və ya mövcud olanı yeniləmək üçün
        setHiddenmessage({commit}, payload) {
            // Database-ə göndəriləcək məlumatları hazırlayırıq
            const data = {
                game_id: payload.game_id || null,
                fullname: payload.fullname || '',
                message: payload.message || '',
                reason: payload.reason || '',
                question: payload.question || '',
                number: payload.number || '',
                actor_rate: payload.actor_rate || 0,
                reception_rate: payload.reception_rate || 0,
                created_at: payload.created_at || new Date().toISOString()
            };

            // Əgər ID varsa update edirik, yoxdursa yeni əlavə edirik
            const request = payload.id
                ? hiddenMessageService.put(payload.id, data)
                : hiddenMessageService.post(null, data);

            return request
                .then(response => {
                    if (response && response.data) {
                        return response.data;
                    }
                    throw new Error('Əməliyyat uğursuz oldu');
                })
                .catch(error => {
                    console.error('Məlumatı yadda saxlayarkən xəta baş verdi:', error);
                    throw error;
                });
        },

        // Gizli mesajı silmək üçün
        deleteHiddenmessage({commit}, id) {
            return hiddenMessageService.delete(id)
                .then(response => {
                    if (response && response.data) {
                        commit('CLEAR_DATA');
                        return response.data;
                    }
                    throw new Error('Silmə əməliyyatı uğursuz oldu');
                })
                .catch(error => {
                    console.error('Məlumatı silərkən xəta baş verdi:', error);
                    throw error;
                });
        },

        // Gizli mesaj üzərində əməliyyat (action) etmək üçün
        actionHiddenmessage({commit}, {id, type, action}) {
            return hiddenMessageService.post('action', {id, type, action})
                .then(response => {
                    if (response && response.data) {
                        return response.data;
                    }
                    throw new Error('Əməliyyat uğursuz oldu');
                })
                .catch(error => {
                    console.error('Əməliyyat zamanı xəta baş verdi:', error);
                    throw error;
                });
        },

        // Store-u təmizləmək üçün
        clearHiddenmessage({commit}) {
            commit('CLEAR_DATA');
        }
    },

    /* Getters */
    getters: {
        // Aktiv gizli mesajı qaytarır
        getCurrentHiddenmessage: (state) => {
            return state.hiddenmessage;
        },

        // Bütün gizli mesajları qaytarır
        getAllHiddenmessages: (state) => {
            return state.hiddenmessages;
        },

        // Gizli mesajların sayını qaytarır
        getHiddenmessagesCount: (state) => {
            return state.hiddenmessages.length;
        }
    }
};

export default HiddenMessageStore;